<template>
  <main 
  v-if="page"
  id="content" 
  aria-labelledby="main-title"
  >
    
    <ElementHeroHome />

    <template v-if="page?.dynamic">
      <template v-for="(section, index) in page?.dynamic" :key="`${index}-${section.__component}`">
        <component :is="getSectionComponent(section.__component)" :sectionIndex="index" :content="section" />
      </template>
    </template>

    <ElementBookDemoCta />

  </main>
</template>

<script setup>

const { $seo } = useNuxtApp()
const route = useRoute()
const contentSections = useContentSections()
const config = useRuntimeConfig()

const page = ref(null)

const { data } = await useAsyncData(
  'home',
  () => $fetch(`${config.public.strapiEndpoint}home?populate=deep,4`)
)

if (!data.value) {
  throw createError({
    statusCode:404
  })
}

page.value = data.value?.data

const seo = computed(() => {
  if (page.value) {
    return $seo(page.value?.seo, route.fullPath)
  }
  return {}
})

useHead(seo)

const getSectionComponent = key => {
  return contentSections[key]
}

</script>